import axios from "axios";


//const baseDomain = "https://conmebolserver.azurewebsites.net/";
//const baseDomain = "http://serverarena.em1click.com.br";
//const baseDomain = "https://localhost:44358/";    

const baseDomain = process.env.VUE_APP_API_BASEDOMAIN;

const baseUrl = `${baseDomain}/api`;

export class Users {

    constructor() {
    }
    
   
    login(user, pwd) {
        return new Promise((resolve, reject) => {            
            const cmd = `${baseUrl}/Login?User=${user}&Pwd=${pwd}`;
            axios.get(cmd).then((r) =>
            {
                if (r.data.header.codigo == 1)
                {                    
                    resolve(true);
                }
                else
                {                    
                    reject(r.data.header.msg);
                }
            }
                , (err) => { reject(err) }
            )
        })
    }
}

export class Stats {

    constructor() {
    }

    Get() {
        return new Promise((resolve, reject) => {
            const cmd = `${baseUrl}/Stats`;            
            axios.get(cmd).then((r) => {                
                if (r.data.header.codigo == 1) {
                    resolve(r.data.data);
                }
                else {
                    reject(r.data.header.msg);
                }
            }
                , (err) => { reject(err) }
            )
        })
    }

}

export class Surveys {

    constructor() {
    }

    Get(filtro) {
        return new Promise((resolve, reject) => {
            const cmd = `${baseUrl}/SurveyList/${filtro}`;
            axios.get(cmd).then((r) => {
                if (r.data.header.codigo == 1) {
                    resolve(r.data.data);
                }
                else {
                    reject(r.data.header.msg);
                }
            }
                , (err) => { reject(err) }
            )
        })
    }

    GetStruct(SurveyId) {
        return new Promise((resolve, reject) => {
            const cmd = `${baseUrl}/SurveyReport/${SurveyId}`;
            //const cmd = `${baseUrl}/Survey`;
            axios.get(cmd).then((r) => {                
                    resolve(r);                
            }
                , (err) => { reject(err) }
            )
        })
    }

    GetScoreGeral(SurveyId) {
        return new Promise((resolve, reject) => {
            const cmd = `${baseUrl}/Score/${SurveyId}`;
            //const cmd = `${baseUrl}/Survey`;
            axios.get(cmd).then((r) => {
                resolve(r.data);
            }
                , (err) => { reject(err) }
            )
        })
    }

    CalculateScoreGeral(SurveyId) {
        return new Promise((resolve, reject) => {
            const cmd = `${baseUrl}/Score/${SurveyId}`;
            //const cmd = `${baseUrl}/Survey`;
            axios.post(cmd, { timeout: 15 }).then((r) => {
                resolve(r.data);
            }   
                , (err) => { reject(err) }
            )
        })
    }


    GetScore(SurveyId, Chap) {
        return new Promise((resolve, reject) => {
            const cmd = `${baseUrl}/SurveyScore/${SurveyId}/${Chap}`;
            //const cmd = `${baseUrl}/Survey`;
            axios.get(cmd).then((r) => {
                resolve(r);
            }
                , (err) => { reject(err) }
            )
        })
    }

    GetCountries() {
        return new Promise((resolve, reject) => {
            const cmd = `${baseUrl}/Country`;
            axios.get(cmd).then((r) => {
                if (r.data.header.codigo == 1) {
                    resolve(r.data.data);
                }
                else {
                    reject(r.data.header.msg);
                }
            }
                , (err) => { reject(err) }
            )
        })
    }

    GetRankigOptions() {
        return new Promise((resolve, reject) => {
            const cmd = `${baseUrl}/RankingOptions`;
            axios.get(cmd).then((r) => {
                if (r.data.header.codigo == 1) {
                    resolve(r.data.data);
                }
                else {
                    reject(r.data.header.msg);
                }
            }
                , (err) => { reject(err) }
            )
        })
    }

}

export class Ranking {

    constructor() {
    }

    Get(pais, tipo, estadio, cap) {
        return new Promise((resolve, reject) => {
            const cmd = `${baseUrl}/Ranking/${pais}/${tipo}/${estadio}/${cap}`;
            axios.get(cmd).then((r) => {
                if (r.data.header.codigo == 1) {
                    resolve(r.data.data);
                }
                else {
                    reject(r.data.header.msg);
                }
            }
                , (err) => { reject(err) }
            )
        })
    }

}

export class Stadiums {

    constructor() {
    }

    Get(pais) {
        return new Promise((resolve, reject) => {
            const cmd = `${baseUrl}/Stadium/${pais}`;
            axios.get(cmd).then((r) => {
                if (r.data.header.codigo == 1) {
                    resolve(r.data.data);
                }
                else {
                    reject(r.data.header.msg);
                }
            }
                , (err) => { reject(err) }
            )
        })
    }

    //DocStadiumController

    GetDocs(stadium) {
        return new Promise((resolve, reject) => {
            const cmd = `${baseUrl}/DocStadium/${stadium}`;
            axios.get(cmd).then((r) => {
                if (r.data.header.codigo == 1) {
                    resolve(r.data.data);
                }
                else {
                    reject(r.data.header.msg);
                }
            }
                , (err) => { reject(err) }
            )
        })
    }

    GetImg(stadium) {
        return new Promise((resolve, reject) => {
            const cmd = `${baseUrl}/ImgStadium/${stadium}`;
            axios.get(cmd).then((r) => {
                if (r.data.header.codigo == 1) {
                    resolve(r.data.data);
                }
                else {
                    reject(r.data.header.msg);
                }
            }
                , (err) => { reject(err) }
            )
        })
    }
}

